nav.nav {
    z-index: 4;
}

.about {
    z-index: 3;

    .close-icon {
        z-index: 3;
    }
}

.loader-wrapper {
    z-index: 6;
}

.ticker {
    z-index: 5;
}

.button-group,
.product-annotation {
    z-index: 2;
}