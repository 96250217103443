nav.nav {
    // border: 1px solid green;
    display: flex;
    position: fixed;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    width: 100%;
    top: $tickerHeight;
    background-color: rgba(0, 0, 0, 0);
    transition: all .5s ease-out;
    box-sizing: content-box;
    margin-top: -5px;

    &.info-active {
        background-color: rgba(0, 0, 0, .95);
    }

    button {
        margin: 0px 20px;
    }

    .snipcart-items-count {
        background-color: black;
        border: 2px solid white;
        height: 22px;
        width: 22px;
        border-radius: 9999px;
        position: absolute;
        right: -10px;
        top: -10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: .8rem;
        transition: all .1s;

        &:hover {
            // color: black;
            // border-color: black;
            // background-color: $green;
        }

    }

    @media screen and #{$mobile-menu} {
        top: calc(#{$tickerHeight} / 1.8); 
    }
}