html {
    height: 100%;
    background-color: $charcoal;
}

body {
    // background-image: url('../../assets/bg-grid.png');
    // background-position: center;
    // background-attachment: fixed;
    position: relative;
    color: white;
    background-color: #202020;
    height: 100%;
    min-height: 100%;
    max-height: 100vh;
    padding-top: $tickerHeight;
    font-family: $family-sans-serif;

    &>#kozmostudio {
        // border: 3px solid red;
        background-color: #202020;
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;
        display: flex;
        flex-direction: column;
        height: calc(100% - #{$tickerHeight});
        margin-bottom: $tickerHeight;

        .content {
            // border: 5px solid blue;
            display: flex;
            flex-direction: column;
            position: relative;
            align-items: center;
            justify-content: center;
            height: 100%;

        }
    }

    a:hover,
    a:focus,
    a:active {
        color: $green;
    }

    @media screen and #{$mobile-menu-off} {
        cursor: none !important;

        a,
        button:hover {
            cursor: none;
        }
    }

    @media screen and #{$mobile-menu} {
        padding-top: calc(#{$tickerHeight} / 1.8);

        &>#kozmostudio {
            // height: calc(100% - (#{$tickerHeight} / 1.8));
            height: calc(100vh + ((#{$tickerHeight} / 1.8)*2));
            margin-bottom: 0;
            padding-bottom: calc(#{$tickerHeight} / 1.8);
        }

    }
}