.circle-cursor {
  // @media screen and #{$mobile-menu-off}{
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
  border-radius: 50%;

  &--outer {
    width: 30px;
    height: 30px;
    border: 1px solid white;
    z-index: 12000;
    opacity: 0.5;
    box-shadow: 0px 0px 10px #000000;
  }

  &--inner {
    width: 5px;
    height: 5px;
    left: -2.5px;
    top: -2.5px;
    z-index: 11000;
    background: white;
    box-shadow: 0px 0px 4px #000000;
  }

  // }
  @media screen and #{$mobile-menu} {
    display: none;
    visibility: hidden;
  }
}