#snipcart {
    /* ----------------------- 
    PART: Input
----------------------- */

    --color-input: white;
    --borderColor-input: rgba(255, 255, 255, .2);
    --bgColor-input: #{$charcoal};

    // /* Hover */
    --color-input-hover: white;
    --borderColor-input-hover:#{$green};
    --bgColor-input-hover:#{$charcoal};
    // --shadow-input-hover

    // /* Focus */
    --color-input-focus: white;
    --borderColor-input-focus: #{$green};
    --bgColor-input-focus: #{$charcoal};
    --shadow-input-focus: transparent;

    // /* Checked */
    // --color-input-checked
    // --borderColor-input-checked
    // --bgColor-input-checked
    // --shadow-input-checked

    // /* Disabled */
    // --color-input-disabled
    // --borderColor-input-disabled
    // --bgColor-input-disabled

    // /* Error */
    // --color-input-error
    // --borderColor-input-error
    // --bgColor-input-error

    // /* Autofill */
    // --bgColor-input-autofill

    // /* ----------------------- 
    //     PART: Label
    // ----------------------- */

    --color-inputLabel: white;

    // /* Hover */
    // --color-inputLabel-hover

    // /* Focus */
    // --color-inputLabel-focus

    // /* ----------------------- 
    //     PART: Icon
    // ----------------------- */

    // --color-inputIcon

    // /* Hover */
    --color-inputIcon-hover: #{$green};

    // /* Focus */
    --color-inputIcon-focus: #{$green};

    // /* Checked */
    --color-inputIcon-checked: blue;

    // /* Disabled */
    // --color-inputIcon-error

    // /* -----------------------
    //     PART: Placeholder
    // ----------------------- */

    // --color-inputPlaceholder
}