.product{
    // border: 2px solid blue;
    position: relative;

    img {
        // max-width: 650px;
        // border: 1px solid blueviolet;
        animation: float 3s ease-in-out infinite;
    }

    &-annotation {
        // border: 1px solid green;
        padding-top: 15px;
        border-top: 2px solid white;
        font-family: $family-sans-serif;
        position: absolute;
        top: 60%;
        transition: all .3s ease;
        opacity: 0;

        &:before {
            content: '';
            width: 6px;
            height: 6px;
            top: -4px;
            background: white;
            
            position: absolute;
            transform: rotate(45deg);
        }

        &>div {
            margin-bottom: 10px;
        }

        &.left {
            &:before{right: 0px;}
            text-align: left;
            left: 0%;
        }

        &.right {
            &:before{left: 0px;}
            text-align: right;
            right: 0%;
        }

    }
    
    &.hover {
        .product-annotation{
            opacity: 1;
        }
    }

    @keyframes float {
        0% {
            transform: translateY(0px)
        }

        50% {
            transform: translateY(8px)
        }

        100% {
            transform: translateY(0px)
        }
    }
}

.product-line {
    border: 1px solid blue;
    // max-width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg{
        border: 1px solid red;
        width: 100%;
    }
   path {

        // stroke-dasharray: 1000;
        // stroke-dashoffset: 1000;
        // animation: dash 1s linear forwards;
    }
}


@keyframes dash {
    to {
      stroke-dashoffset: 0;
    }
  }