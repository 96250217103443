$borderStyle: 2px solid white;
$tickerSpeed: 20s;

.ticker {
    height: $tickerHeight;
    background-color: $charcoal;
    display: flex;
    position: fixed;

    img {
        // border: 1px solid blue;
        width: 100vw;
        margin-left: 15px;
    }

    span {
        width: 200vw;
        display: inline-flex;
    }

    &.top {
        border-bottom: $borderStyle;
        top: 0;

        span {
            animation: slide $tickerSpeed forwards linear infinite;
        }
    }

    &.bottom {
        border-top: $borderStyle;
        top: calc(100% - #{$tickerHeight});

        span {
            animation: slide $tickerSpeed reverse linear infinite;
        }
    }


    @keyframes slide {
        0% {
            transform: translateX(0vw);
        }

        100% {
            // transform: translateX(0vw);
            transform: translateX(-100vw);
        }
    }

    @media screen and #{$mobile-menu} {
        padding: 0;
        height: calc(#{$tickerHeight} / 1.8);
        
        &.bottom{
            top: calc(100vh - (#{$tickerHeight} / 1.8));
        }

        img {
            width: 200vw;
        }

        @keyframes slide {
            0% {
                transform: translateX(0vw);
            }

            100% {
                transform: translateX(-400vw);
                // transform: translateX(0vw);
            }
        }
    }

}