#snipcart {
    /* Default */
    --color-link: white;
    // --borderColor-link
    // --bgColor-link

    // /* Hover */
    --color-link-hover: rgba(255, 255, 255, .5);
    // --borderColor-link-hover
    // --bgColor-link-hover

    // /* Active */
    --color-link-active: white;
    // --borderColor-link-active
    // --bgColor-link-active

    // /* Focus */
    --color-link-focus: white;
    // --borderColor-link-focus
    // --bgColor-link-focus
    // --shadow-link-focus
}