#snipcart {
    // /* Default (completed) */
    --color-badge: white;
    --borderColor-badge: white;
    --bgColor-badge: black;

    // /* Active */
    --color-badge-active: white;
    --borderColor-badge-active: white;
    --bgColor-badge-active: black;

    // /* Disabled */
    --color-badge-disabled: rgba(255, 255, 255, .3);
    --borderColor-badge-disabled: rgba(255, 255, 255, .3);
    --bgColor-badge-disabled: rgba(0, 0, 0, .3);
    ;
}