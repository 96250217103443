.about {
    position: fixed;
    overflow-x: hidden;
    overflow-y: scroll;
    font-family: $family-sans-serif;
    font-size: 3vw;
    line-height: 3.25vw;
    width: 100vw;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, .85);
    text-transform: uppercase;
    margin-top: 0;
    padding-top: $tickerHeight;

    //closed settings
    height: 0%;
    padding-bottom: 0px;
    transition: all .5s ease;

    &.active {
        height: 100%;
        padding-bottom: 100px;

    }

    .scrollArrow {
        width: 60px;
        position: fixed;
        right: 75px;
        bottom: 100px;
        opacity: 0;
        transition: all 1s;
        animation: flicker 1s alternate-reverse linear infinite;
    }

    .close-icon {
        position: relative;
        // height: 50px;
        // width: 50px;
        border: 2px solid white;
        padding: 10px;
        border-radius: 9999px;
        background-color: $charcoal;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2rem;
        cursor: pointer;
    }

    a {
        color: white;
        text-decoration: underline;
        text-transform: uppercase;
        padding: 0px 30px;
        max-width: 80vw;
    }

    h2 {
        font-family: KOZMOUltra;
        border-top: 1px solid white;
        border-bottom: 1px solid white;
        padding: 6vw 30px;
        margin: 4vw auto;
        font-size: 10vw;

        &:first-of-type {
            // border-top: none;
        }
    }

    button {
        margin: 30px;
    }

    .info,
    .contact,
    .instagram {
        display: block;
        // border: 2px solid white;
        // margin-top: .5vw;
    }

    .info {
        // margin-top: -20px;
        // margin-left: 20px;
        // padding: 30px;
        // max-width: 900px;
        // border: 1px solid blue;
        position: relative;
        top: 2px;
        text-align: left;
        min-width: 600px;
        flex-direction: column;

        p,
        ul {
            padding: 0px 30px;
            max-width: 80vw;
            white-space: normal;
            margin-bottom: 20px;
        }

        @keyframes flicker {
            0% {
                opacity: 0;
            }

            // 50% {
            //     opacity: 1;
            // }

            100% {
                opacity: 1;
            }
        }

    }

    /*
    @media screen and #{$mobile-menu} {
        margin-left: 0;
        padding: 10px;
        margin-top: 0px;
        position: fixed;
        top: 0;
        left: 0;
        min-width: 100%;
        height: 100%;
        background-color: rgba(21, 21, 21, .9);
        overflow-y: scroll;
        padding-bottom: 80px;


        .close-icon {
            margin-left: 20px;
        }

        .info {
            margin-left: 0;
            min-width: 100%;
        }

        .contact {
            transform: translateX(-50%);
            left: 50%;
            font-size: 1.5rem;
            // top: 0;
            // bottom: 0;
        }
    }*/
    @media screen and #{$mobile-menu} {
        font-size: 2rem;
        line-height: 2.2rem;
        // margin-bottom: 20px;
        padding-top: calc(#{$tickerHeight} / 1.8);

        &.active {
            padding-top: 90px;
            // padding: 130px 20px 20px 20px;
            // margin-top: $tickerHeight;
            // padding-bottom: 70px;
        }

        .info {
            min-width: 100%;
            top: 10px;

            p,
            ul,
            a {
                font-size: 1.25rem;
                line-height: 1.25;
                max-width: 100%;
            }
        }

        .scrollArrow {
            right: 10px;
            bottom: 65px;
            width: 30px;
        }
    }
}