// $charcoal: #333333;
$black: #151515;
$gray:#D3D3D3;
$light: #FAF9F9;
$tertiary: #DEDEB9;
// $radius: 6px;

header {
    background: $black;
    padding: 40px 20px;
    color: white;

    img {
        max-height: 40px;
    }

    h1 {
        font-size: 4rem;
        margin-bottom: 0px;
        font-family: $family-sans-serif;
    }

    h3 {
        color: $tertiary;
    }

    h1,
    h3 {
        margin-top: 0;
    }
}


.email-signature {
    display: flex;
    // flex-wrap: wrap;
    // border: 1px solid red;
    // padding: 20px;
    // min-height: 80vh;
    width: 100%;

    .sig-form,
    .sig-result {
        // width: 50%;
        // flex: 1;
        padding: 20px;
        padding-top: 100px;
    }

    .sig-form {
        // background: $charcoal;
        color: $gray;
        width: 40%;

        form {
            display: flex;
            flex-direction: column;

            label {
                font-weight: bold;
                margin-bottom: 5px;
            }

            hr {
                border-style: unset;
                border-top: 1px solid $light;
                width: 100%;
                margin-top: 17px;
                margin-bottom: 25px;
            }

            select {
                max-width: 175px;
                padding: 6px;
                font-size: 16px;
                margin-bottom: 15px;
                border-radius: $radius;
            }

            input {
                height: 34px;
                padding: 6px 12px;
                font-size: 14px;
                margin-bottom: 15px;
                border-style: unset;
                border-radius: $radius;
            }
        }
    }

    .sig-result {
        background: $light;
        width: 60%;

        #preview {
            h3 {
                border-bottom: 1px solid gray;
                padding: 20px;
                margin-top: 0;
                background-color: $black;
                color: white;
            }

            background: white;
            border: 1px solid gray;
            border-radius: $radius;
            max-width: 600px;

            #signature {
                margin-left: 20px;
                margin-bottom: 20px;
            }
        }

        button {
            margin-top: 20px;
        }
    }

}