#snipcart {
    // /* Default */
    --color-default: white;
    --borderColor-default: white;
    --bgColor-default: #{$charcoal};

    // /* Alt */
    // --color-alt: $whi;
    --bgColor-alt: #{$charcoal};

    // /* Icon */
    --color-icon: white;

    // /* Success */
    // --color-success
    // --bgColor-success

    // /* Error */
    // --color-error
    // --borderColor-error
    --bgColor-error: #{$red};

    // /* Info */
    --bgColor-info: black;

    /* Modal */
    --bgColor-modal: #{$charcoal};
    --bgColor-modalVeil: rgba(1, 1, 1, .8);

    /* Overrides */
    .snipcart-item-line--cart-edit {
        border-color: rgba(255, 255, 255, .2);
    }

    .snipcart__font--secondary,
    .snipcart__font--subtitle,
    .snipcart__font--subtitle-small,
    .snipcart-base-button__label {
        font-family: $family-sans-serif;
        text-transform: uppercase;
    }

    .card_label {
        color: white;

    }
}