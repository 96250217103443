#snipcart {
    // /* Default */
    --color-buttonPrimary: white;
    --borderColor-buttonPrimary: white;
    --bgColor-buttonPrimary: black;

    // /* Hover */
    --color-buttonPrimary-hover: black;
    --borderColor-buttonPrimary-hover: black;
    --bgColor-buttonPrimary-hover: #{$green};
    // --shadow-buttonPrimary-hover
    /*Secondary*/
    --color-buttonSecondary: white;
    --borderColor-buttonSecondary: white;
    --bgColor-buttonSecondary: #{$charcoal};
    /* Secondary Hover*/
    --color-buttonSecondary-hover: white;
    --borderColor-buttonSecondary-hover: #{$green};
    --bgColor-buttonSecondary-hover: black;

    /*Danger*/
    // --color-buttonDanger: #{$red};
    // --borderColor-buttonDanger: #{$red};
    // --bgColor-buttonDanger: #{$charcoal};

    // .snipcart-button-icon.is-danger {
    //     border-color: var(--borderColor-buttonDanger, yellow) !important;
    // }



    // /* Active */
    // --color-buttonPrimary-active:;
    --borderColor-buttonPrimary-active: #{$green};
    --bgColor-buttonPrimary-active: rgba(255, 255, 255, .1);
    // --shadow-buttonPrimary-active

    // /* Focus */
    // --color-buttonPrimary-focus
    --borderColor-buttonPrimary-focus: #{$green};
    --bgColor-buttonPrimary-focus: rgba(255, 255, 255, .1);
    --shadow-buttonPrimary-focus: black;

    // /* Disabled */
    // --color-buttonPrimary-disabled
    // --borderColor-buttonPrimary-disabled
    // --bgColor-buttonPrimary-disabled

    // /* Success */
    // --color-buttonPrimary-success: purple;
    // --borderColor-buttonPrimary-success
    // --bgColor-buttonPrimary-success

    // /* Error */
    // --color-buttonPrimary-error
    // --borderColor-buttonPrimary-error
    // --bgColor-buttonPrimary-error

    .snipcart-base-button {
        border-radius: 9999px;
    }
}