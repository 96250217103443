.shop {
    text-align: center;
    min-height: 0;
    // border: 3px solid yellow;
    display: flex;
    height: 100%;
    flex-direction: column;
    // align-items: center;
    justify-content: space-evenly;
    .column {
        // border: 5px solid pink;
        padding: 0;

        &s {
            max-width: 80vw;
            // border: 5px solid teal;
            margin: 0 auto;
            // flex: 1;
            // min-height: 0;
        }

    }
}