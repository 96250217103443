.loader {
    &-wrapper {
        // border: 4px solid yellow;
        padding: 20px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(21, 21, 21, .9);
        position: fixed;
        height: 100%;
        width: 100%;
        // perspective: 600px;
    }

    animation: ySpin 5s ease-in-out infinite;
    // border: 1px solid blue;
    height: 400px;
    width: 400px;

    img {
        // border: 1px solid salmon;
        width: 100%;
    }

    @keyframes ySpin {
        0% {
            transform: rotateY(0deg);
        }

        100% {
            transform: rotateY(360deg);
        }
    }
}