.button.pill {
	background: black;
	color: white;
	text-transform: uppercase;
	font-size: 1rem;
	border-width: 2px;
	transform: perspective(1px) translateZ(0);
	transition: all .3s;

	&:hover {
		background-color: $green;
		border-color: black;
		color: $black;

		.snipcart-items-count {
			color: black;
			border-color: black;
			background-color: $green;
		}
	}
}

.button:focus,
.button.is-focused {
	border-color: $green;
}

$radioSize: 40px;

.button-group {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	// padding-bottom: 30px;
	// border: 3px solid brown;

	.snipcart-add-item {
		@media screen and #{$mobile-menu-off} {
			//Desktop
			margin-left: 30px;
		}

		@media screen and #{$mobile-menu} {
			//Mobile
			margin-top: 30px;
		}

	}

	@media screen and #{$mobile-menu} {
		flex-direction: column;
	}

}

.sizes {
	background-color: black;
	border: 2px solid white;
	border-radius: 9999px;
	// max-width: 400px;
	// margin: 0 auto;
	// align-items: center;
	color: white;
	// margin-bottom: 30px;
	// padding: 15px 30px;
	display: inline-block;
	// padding-top: calc(0.5em - 1px);
	// padding-bottom: calc(0.5em - 1px);
	padding-left: calc(1em + 0.25em);
	padding-right: calc(1em + 0.25em);

	.pill {
		width: 50px;
		height: 50px;
		background: transparent;
		padding: 15px;
		// border: 1px solid orange;
		margin-bottom: 0;
		// color: rgba(255, 255, 255, .5);
		text-decoration: none;

		&.is-selected {
			// color: rgba(255, 255, 255, 1);
			border: 3px solid white;
			border-radius: 9999px;
			// background-color: $charcoal;
			// color: black;
		}

		&:hover {
			background: transparent;
			// color: rgba(255, 255, 255, 1);
		}
	}

	.radio {
		// border: 1px solid orange;
		// width: $radioSize;
		// height: $radioSize;
		font-size: 1rem;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		font-family: $family-sans-serif;
		padding-top: calc(0.5em - 1px);
		padding-bottom: calc(0.5em - 1px);

		&.selected {
			// border: 3px solid white;
			// border-radius: 9999px;
			color: $green;
		}

		&:hover {
			color: $green;
			cursor: inherit;
		}
	}

}