@charset "utf-8";

@font-face {
    font-family: KOZMOReg;
    src: url(../assets/fonts/KOZMO-Regular.woff2)
}

@font-face {
    font-family: KOZMOUltra;
    src: url(../assets/fonts/KOZMO-Ultra.woff2)
}

//Set colors
$black: black;
$charcoal: #151515;
$green: #CAFE55;
$red: red;
$gray: smoke;


$input-hover-color: white;
$input-disabled-color: gray;

//Update Bulma vars
// $primary
$family-sans-serif:'KOZMOReg',
-apple-system,
"Segoe UI",
"Roboto",
"Oxygen",
"Ubuntu",
"Cantarell",
"Fira Sans",
"Droid Sans",
"Helvetica Neue",
sans-serif;
$body-size: 16px;
$link: white;

$mobile-menu: (max-width: 1030px);
$mobile-menu-off: (min-width: 1031px);

$tickerHeight: 90px;

//Import Bulma stuff
@import "bulma/sass/utilities/_all.sass";
@import "bulma/sass/base/_all.sass";
@import "bulma/sass/elements/button.sass";
@import "bulma/sass/grid/columns.sass";
@import "bulma/sass/elements/container.sass";
@import "bulma/sass/layout/section.sass";
@import "bulma/sass/form/_all.sass";

@import "./partials/reset.scss";
@import "./partials/page.scss";
@import "./partials/ticker.scss";
@import "./partials/nav.scss";
@import "./partials/button.scss";
@import "./partials/loader.scss";
@import "./partials/modifiers.scss";
@import "./partials/cursor.scss";
@import "./partials/z-layers.scss";
@import "./partials/product.scss";

@import "./pages/shop.scss";
@import "./pages/about.scss";
@import "./pages/email.scss";

@import "./snipcart/global.scss";
@import "./snipcart/input.scss";
@import "./snipcart/button.scss";
@import "./snipcart/link.scss";
@import "./snipcart/badges.scss";